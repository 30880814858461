<template>
  <div>

    <b-form-group
      label="Référence de colis"
      label-for="vi-first-name"
    >
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="MaximizeIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="ref"
          placeholder="Référence de colis"
          @change="checkOrder"
        />
      </b-input-group>
    </b-form-group>
    <validation-observer ref="simpleRules">
      <!-- table -->
      <div class="d-flex justify-content-between">
        <b-card>
          <b-form-group
            v-if="user.role === 'admin'"
            label="De Dépôt"
          >
            <validation-provider
              #default="{ errors }"
              name="Depot"
              rules="required"
            >
              <v-select
                v-model="repository"
                :clearable="false"
                placeholder="Depots"
                label="name"
                :options="repositories"
                @input="getToRepositories"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          Nombre : {{ orders.length }}
          <vue-good-table
            :columns="columns"
            :rows="orders"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'id'">
                <span> {{ props.row.id }}</span><br>
                <span
                  v-if="props.row.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <feather-icon
                    :id="`invoice-row-${props.row.id}-show-icon`"
                    icon="CheckSquareIcon"
                    class="cursor-pointer mr-1"
                    size="16"
                    color="green"
                    @click="addItem(props.row)"
                  />
                  <b-tooltip
                    title="Ajouter"
                    class="cursor-pointer"
                    :target="`invoice-row-${props.row.id}-show-icon`"
                  />

                </span>
              </span>

              <!-- Column: Common -->

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
        <b-card class="ml-1">
          <b-form-group
            label="A Dépôt"
          >
            <validation-provider
              #default="{ errors }"
              name="Depot"
              rules="required"
            >
              <v-select
                v-model="toRepository"
                :clearable="false"
                placeholder="Depots"
                label="name"
                :options="toRepositories"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          Nombre : {{ selected.length }}
          <vue-good-table
            :columns="columns"
            :rows="selected"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'id'">
                <span> {{ props.row.id }}</span><br>
                <span
                  v-if="props.row.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <feather-icon
                    :id="`invoice-row-${props.row.id}-show-icon`"
                    icon="DeleteIcon"
                    color="red"
                    class="cursor-pointer mr-1"
                    size="16"
                    @click="resetItem(props.row)"
                  />
                  <b-tooltip
                    title="Annuler"
                    class="cursor-pointer"
                    :target="`invoice-row-${props.row.id}-show-icon`"
                  />

                </span>
              </span>

              <!-- Column: Common -->

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>

          </vue-good-table>
          <b-form-group
            label="Livreur"
          >
            <validation-provider
              #default="{ errors }"
              name="Livreur"
              rules="required"
            >
              <v-select
                v-model="deliveryMan"
                :clearable="false"
                placeholder="Livreur"
                label="username"
                :options="deliveryMans"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Voiture"
          >
            <validation-provider
              #default="{ errors }"
              name="voiture"
              rules="required"
            >
              <v-select
                v-model="selectedCar"
                :clearable="false"
                placeholder="Choisir une voiture"
                label="serial_number"
                :options="cars"
                :reduce="car => car.id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="d-flex justify-content-end">
            <b-button
              ref="saveButton"
              variant="gradient-primary"
              class="ml-1 d-inline-block"
              :disabled="isSubmitting"
              @click="addTransferVoucher"
            >
              <feather-icon icon="PlusIcon" />
              <span class="ml-1">Enregistrer</span>
            </b-button>
          </b-form-group>
        </b-card>

      </div>
    </validation-observer>
  </div></template>

<script>
import {
  BCard, BButton, BPagination, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormSelect, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
// import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import storeAuth from '@/store/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import ok from '@/assets/audio/ok.mp3'
import notexist from '@/assets/audio/notexist.mp3'
import alreadyScanned from '@/assets/audio/colis-deja-scanne.mp3'

export default {
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    // flatPickr,
    BPagination,
    BTooltip,
    vSelect,
    BCard,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      deliveryMans: [],
      deliveryMan: '',
      repositories: [],
      toRepositories: [],
      repository: '',
      toRepository: '',
      date: '01-10-2021',
      status: '',
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'code',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher code',
          },
        },
        {
          label: 'nom',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nom',
          },
        },
        {
          label: 'gouvernorat',
          field: 'governorate',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher gouvernorat',
          },
        },
        {
          label: 'adresse',
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher adresse',
          },
        },
        {
          label: 'tel',
          field: 'phone1',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher tel',
          },
        },
      ],
      role: '',
      orders: [],
      cars: [],
      selected: [],
      searchTerm: '',
      ref: '',
      user: {},
      isLoading: false,
      selectedCar: null,
      ok,
      notexist,
      alreadyScanned,
      isSubmitting: false,
    }
  },
  created() {
    this.user = storeAuth.state.user
    this.getDeliveryMans()
    if (this.user.role === 'admin') {
      this.getRepositories()
    } else {
      this.getOrdersAuDepot()
      this.getToRepositories()
    }
    this.getCars()
  },
  methods: {
    async playAudio(payload) {
      const voice = new Audio(payload)
      voice.addEventListener('canplaythrough', () => {
        voice.play()
      })
    },
    exists(id) {
      return this.items.some(el => el.code === Number(id))
    },
    withTitle(text) {
      this.$swal({
        title: 'Alerte',
        icon: 'warning',
        text,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(() => {
        this.ref = ''
      })
    },
    async getRepositories() {
      try {
        const { data } = await axios.get('/api/repositories/')
        this.repositories = data
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', 'network error!')
        }, 1000)
      }
    },
    async getToRepositories() {
      this.getOrdersAuDepot()
      this.selected = []
      try {
        const { data } = await axios.get('/api/repositories/')
        const repositories = data
        if (this.user.role === 'manager') {
          this.toRepositories = repositories.filter(element => element.id !== this.user.repository)
        } else {
          this.toRepositories = repositories.filter(element => element.id !== this.repository.id)
        }
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', 'network error!')
        }, 1000)
      }
    },
    checkOrder() {
      const result = this.orders.find(element => {
        if (element.supplier.id === 504) {
          return element.bestway_barcode === Number(this.ref) || element.id === Number(this.ref)
        }
        return element.id === Number(this.ref)
      })

      const selected = this.selected.find(element => element.id === Number(this.ref))

      if (result) {
        this.addItem(result).then(() => {
          this.ref = ''
          this.playAudio(ok)
        })
      } else if (selected) {
        this.withTitle('Colis déja scanné')
        this.playAudio(alreadyScanned)
      } else {
        this.withTitle('Colis non exist')
        this.playAudio(notexist)
      }
    },
    async addTransferVoucher() {
      if (this.isSubmitting) {
        return
      }
      this.isSubmitting = true
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const fromRepository = this.user.role === 'admin' ? this.repository.id : this.user.repository.id
          const orderIds = this.selected.map(order => order.id)
          const payload = {
            delivery_man: this.deliveryMan.id,
            orders: orderIds,
            from_repository: fromRepository,
            to_repository: this.toRepository.id,
            car: this.selectedCar,
          }
          try {
            const { data } = await axios.post('/api/transfer-vouchers/create-transfer-voucher-orders/', payload)
            this.$router.push(`/bons-transferts/show/${data.transfer_voucher_id}`)
            this.showToast('success', 'top-center', 'Bon de transfert ajouté avec succès')
          } catch (error) {
            this.showToast('danger', 'top-center', error.toString())
          } finally {
            this.isSubmitting = false
          }
        } else {
          this.showToast('danger', 'top-center', 'Champ obligatoire ! Vérifiez vos données !')
          this.isSubmitting = false
        }
      })
    },
    async getDeliveryMans() {
      this.isLoading = true
      try {
        const { data } = await axios.get('api/auth/users/', {
          params: {
            role: 'delivery_man',
            repository: this.user.repository,
          },
        })
        this.deliveryMans = data
        this.isLoading = false
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', 'network error: ')
        }, 1000)
      }
    },
    async addItem(item) {
      this.selected.push(item)
      const i = this.orders.findIndex(element => element.id === item.id)
      this.orders.splice(i, 1)
    },
    async resetItem(item) {
      this.orders.push(item)
      const i = this.selected.findIndex(element => element.id === item.id)
      this.selected.splice(i, 1)
    },
    async getOrdersAuDepot() {
      this.isLoading = true
      let { repository } = this.user
      if (this.user.role === 'admin') {
        repository = this.repository.id
      }
      try {
        const { data } = await axios.get('/api/orders/transfere/', {
          params: {
            repository,
          },
        })
        this.orders = data
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', 'network error: ')
        }, 1000)
      }
    },
    async getCars() {
      this.isLoading = true
      try {
        const { data } = await axios.get('/api/cars/')
        this.cars = data
        this.isLoading = false
      } catch (error) {
        this.showToast('danger', 'top-center', 'network error')
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
